








import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import StudentFaceIcon from '@/components/atoms/StudentFaceIcon.vue'

@Component({
  components: {
    ColoredBox,
    StudentFaceIcon,
  },
})
export default class StudentComment extends Vue {
  @Prop()
  comment!: { face: number; comment: string; iconDisable?: boolean }

  private get iconDescription(): string {
    let description = ''
    if (this.comment.iconDisable) return description
    switch (this.comment.face) {
      case 1:
        description = 'とてもよくできた！'
        break
      case 2:
        description = 'できた！'
        break
      case 3:
        description = '少し不安'
        break
      case 4:
        description = 'できなかった'
        break
      case 5:
        description = '全然できなかった'
        break
    }
    return description
  }

  private get commentDetail(): { face: number; faceEnable: boolean; comment: string } {
    const { face, comment } = this.comment
    if (face && comment) {
      return { face: face, faceEnable: true, comment: comment }
    } else {
      return { face: 1, faceEnable: false, comment: '振り返りはありません。' }
    }
  }
}
