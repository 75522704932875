



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { LessonResultInformationType } from '@/models/api/lessonResults'

@Component
export default class StudentHomeworkHistoryContent extends Vue {
  @Prop()
  title!: string
  @Prop()
  resultInformation!: LessonResultInformationType
  @Prop({ default: false })
  isTeacher?: boolean

  private resultUrl(): string {
    const resultId = this.resultInformation.resultId || 0
    return this.isTeacher
      ? `/teacher/history/drillResult/${resultId}?prevPage=${this.$route.name}`
      : `/student/history/drillResult/${resultId}?prevPage=${this.$route.name}`
  }
}
